import CustomDialog from "../../../components/mui/dialog";
import { FC } from "react";
import { findNameByLanguage } from "../../../utils";
import { useLanguage } from "../../../components/language-provider";
import { useState, useEffect } from "react";
import { getUserDetail, myEcoSavings } from "../../../services/User";
import { capitalizeFirstLetter } from "../../../utils";
import Loader from "../../../components/mui/loader/Loader";
import "./index.scss";
import { getTripsCount } from "../../../services/Trips";



const UserDetail: /* FC<IUserProps> */ FC<any> = ({
  isOpen,
  userId,
  onClose,
  isDisabled,
}) => {
  const { language } = useLanguage();
  const [userInfo, setUserInfo] = useState({
    fullName: "",
    image: "",
    line1: "",
    line2: "",
    state: "",
    city: "",
    country: "",
    zip: "",
    email: "",
    phone: "",
    role: "",
    gender: "",
    preference: "",
    uniqueId: "",
    loading: false,
    ecoCredits:"",
    ecoTokens:"",
    carbonSaving:"",
    ecoSavings: [],
    licenceCheckCode: "",
    allowedMarketPlaceUsersCount: 10,
    licenceNumber: "",
    tripCounts: 0,
    geo: [0, 0],
    isBeta: false
  });



  /*eslint-disable*/
  useEffect(() => {
    if (userId && isOpen) {
      setUserInfo((prev) => ({ ...prev, loading: true }));
      Promise.all<any>([getUserDetail({ _id: userId }), myEcoSavings(userId), 
        getTripsCount(userId, new Date(new Date(new Date().setUTCDate(1)).setHours(0, 0, 0, 0)), new Date())
      ])
        .then(([userDetails, ecoSavings, tripsCountData]) => {
          const { user,ecoTokens,carbonSavings,ecoCredits } = userDetails;

          setUserInfo((prev) => ({
            ...prev,
            fullName: `${capitalizeFirstLetter(
              findNameByLanguage(language, user.firstName)
            )} ${capitalizeFirstLetter(
              findNameByLanguage(language, user.lastName)
            )}`,
            image: user.photo,
            line1: `${findNameByLanguage(language, user.address.line1)}`,
            line2: `${findNameByLanguage(language, user.address.line2)}`,
            city: `${findNameByLanguage(language, user.address.city)}`,
            state: `${findNameByLanguage(language, user.address.state)}`,
            country: `${findNameByLanguage(language, user.address.country)}`,
            zip: user.address.zip,
            geo: user.address.geo,
            email: user.email,
            phone: `${
              user.countryCode
                ? user.countryCode + " " + user.phone
                : user.phone
            }`,
            role: user.role.roleType,
            gender: user.gender,
            preference: user.userPreference,
            uniqueId: user.uniqueId,
            ecoCredits: ecoCredits?.data?.totalEcoCredits,
            ecoTokens: ecoTokens?.data?.current.ecoTokens || 0,
            carbonSaving: carbonSavings?.data?.totalCarbonSaving,
            loading: false,
            licenceCheckCode: user.licenceCheckCode || "Null",
            allowedMarketPlaceUsersCount: user.allowedMarketPlaceUsersCount || 0,
            licenceNumber: user.licenceNumber || "Null",
            tripCounts: tripsCountData.data.tripsCount, 
            isBeta: user.isBeta || user.isBetaTest
          }));

          const {
            data: { categoryWisedata },
          } = ecoSavings;
          setUserInfo((prev) => {
            return {
              ...prev,
              ecoSavings: categoryWisedata,
            };
          });
          console.log(categoryWisedata);
        })
        .catch((error) => console.log(error));
    }
  }, [userId]);

  /*eslint-enable*/

  return (
    <>
      {userInfo.loading ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"Details"}
          isOpen={isOpen}
          onClose={onClose}
          disabled={isDisabled}
        >
          <div className="user-detail-container ">
            <div className="top-heading-container">
              <b>User Details</b>
            </div>
            <div className="user-detail-card">
              <div className="user-row">
                <div className="col-xs-12 user-image-container">
                  <img
                    src={userInfo.image}
                    className="user-profile-image"
                    alt="user-pic"
                  />
                </div>
                <div className="col-md-6 col-xs-12 user-details">
                  <span>
                    <b>{userInfo.fullName}</b>
                  </span>
                  {
                    // !userInfo.geo.every((item) => item === 0) &&
                    (userInfo.line1.length || userInfo.line2.length || userInfo.city.length || userInfo.state) &&
                  (<address className="text-wrap">
                    {userInfo.line1},&nbsp;
                    {userInfo.line2}&nbsp;
                    {userInfo.city}
                    ,&nbsp;{userInfo.state},&nbsp; {userInfo.country},&nbsp;{" "}
                    {userInfo.zip}
                  </address>)
                  }
                    {
                      userInfo.gender && userInfo.gender.length && 
                    (<>
                      {/* <br /> */}
                      <span><b>Gender : </b></span>
                      <span>{capitalizeFirstLetter(userInfo.gender || '')}</span>
                    </>)
                    }

                  {
                      userInfo.email && userInfo.email.length &&
                      (<><br />
                        <span><b>Email : </b></span>
                        <span>{userInfo.email}</span></>)
                  }

                  <br />
                  <span><b>Phone : </b></span>
                  <span>{userInfo.phone}</span>
                  <br />
                  <span><b>Beta : </b></span>
                  <span>{userInfo.isBeta?.toString() ?? 'false'}</span>
                </div>
              </div>
            </div>

            <hr
              style={{
                marginTop: "16px",
                marginBottom: "16px",
                borderTop: "1px solid #0000001a",
              }}
            />

            <div className="trip-detail-card">
              <div className="trip-detail-heading">
                <b className="trip-heading">Other Details</b>
              </div>
              <div className="trip-details">
                <div className="field-value">
                  <span>Role</span>
                  <span>{capitalizeFirstLetter(userInfo.role)}</span>
                </div>
                <div className="field-value">
                  <span>Preference</span>
                  <span>{capitalizeFirstLetter(userInfo.preference)}</span>
                </div>
                <div className="field-value">
                  <span>Unique Id</span>
                  <span>{userInfo.uniqueId}</span>
                </div>
                <div className="field-value">
                  <span>Total Eco-credits</span>
                  <span>{userInfo.ecoCredits}</span>
                </div>
                <div className="field-value">
                  <span>Carbon saving</span>
                  <span>{userInfo.carbonSaving}</span>
                </div>
                <div className="field-value">
                  <span>Eco Tokens</span>
                  <span>{userInfo.ecoTokens}</span>
                </div>
                <div className="field-value">
                  <span>Onboarding status</span>
                  <span>Pending</span>
                </div>

                <div className="field-value">
                  <span>Licence number</span>
                  <span>{userInfo.licenceNumber}</span>
                </div>

                <div className="field-value">
                  <span>Licence check code</span>
                  <span>{userInfo.licenceCheckCode}</span>
                </div>

                <div className="field-value">
                  <span>Allowed market place invites</span>
                  <span>{userInfo.allowedMarketPlaceUsersCount}</span>
                </div>

                <div className="field-value">
                  <span>Trip counts</span>
                  <span>{userInfo.tripCounts}</span>
                </div>
              </div>
            </div>
          </div>
        </CustomDialog>
      )}
    </>
  );
};

export default UserDetail;
