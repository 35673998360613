import { ChangeEvent, useEffect, useState } from 'react';
import Header from '../../../components/header';
import CustomTable from '../../../components/mui/table';
import {
  IconButton,
  Switch,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Box
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAllLocations, getLocationEcoSavings } from '../../../services/configurations/locations';
import { getAllCategories } from '../../../services/User';
import { useSnackbar } from 'notistack';
import { findNameByLanguage } from '../../../utils';
import { useLanguage } from '../../../components/language-provider';
import SetLocation from '../../../components/setLocation';
import WarningDialog from '../../../components/mui/warning-dialog';
import { deleteLocation, updateStatus } from '../../../services/configurations/locations';
import { capitalizeFirstLetter } from '../../../utils';
import Loader from '../../../components/mui/loader/Loader';
import { formatAddress } from '../../../utils';
import ManageLocation from './manage-location';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MatchedUsers from './matched-users';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { updateCarbonOffset } from '../../../services/configurations/carbon-config';
import { purchaseCarbonAvoidanceCredits } from '../../../services/carbon-credits';

const columns = [
  {
    id: 'category',
    label: 'Location Category'
  },
  {
    id: 'locationName',
    label: 'Location Name'
  },
  {
    id: 'address',
    label: 'Location Address'
  },
  {
    id: 'ecologiURL',
    label: 'Ecology URL'
  },
  {
    id: 'locationURL',
    label: 'Location URL'
  },
  {
    id: 'adminSignUpURL',
    label: 'Admin URL'
  },
  {
    id: 'onBoardingStatus',
    label: 'Onboarding Status'
  },
  {
    id: 'otherFee',
    label: 'Other Fee'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'co2Savings',
    label: 'Total CO2 Savings'
  },
  {
    id: 'carbonOffset',
    label: 'Total CO2 Offset'
  },
  {
    id: 'action',
    label: 'Action'
  },
];

const Locations = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { language } = useLanguage();

  const [locationStates, setLocationStates] = useState({
    locationList: [],
    status: 'ALL',
    categories: [],
    categoryId: null,
    deleteDialog: false,
    _action: "",
    locationDialog: false,
    latitude: undefined,
    longitude: undefined,
    addLocationDialog: false,
    type: '',
    value: '',
    page: 1,
    matchedUsersDialog: false,
    totalPage: 1,
    loading: true,
    matchedDialogLocationId: "",
    matchedDialogLocationName: ""
  })
  const [location, setLocation] = useState()
  const [payload, setPayload] = useState<any>()

  const handleManage = (type: 'new' | 'edit') => {
    setLocationStates((prevState) => {
      return {
        ...prevState,
        addLocationDialog: !prevState.addLocationDialog,
        type,
      }
    });
  }

  const shortenUrl = (url: string, maxLength = 20) => {
    if (url) {
      if (url.length <= maxLength) {
        return <span>{url}</span>;
      }
      const truncatedUrl = url.substring(0, maxLength - 3) + '...';
      return (
        <Tooltip title={url}>
          <span>{truncatedUrl}</span>
        </Tooltip>
      );
    }
    return ""
  };

  const handleClose = () => {
    setLocationStates(prevState => {
      return {
        ...prevState,
        addLocationDialog: false
      }
    });
  }

  const getRoute = (type: string, isActivated?: boolean) => {
    return (type === "PUBLIC" || (type === "PRIVATE" && isActivated)) ? 'public-signup' : 'private-signup'
  }

  const onPageChange = (e: ChangeEvent<unknown>, newPage: number) => {
    setLocationStates({ ...locationStates, page: newPage });
  }

  const handleSearch = (event: ChangeEvent<unknown> | any) => {
    const searchValue = event.target.value;
    if (event.key === "Enter") {
      fetchData(searchValue);
    }
  };

  const onEdit = (id: string) => {
    setLocationStates((prev) => {
      return {
        ...prev,
        _action: id
      }
    })
  }

  const onDelete = () => {
    deleteLocation(locationStates._action)
      .then((res) => {
        setLocationStates((prev) => {
          return {
            ...prev,
            deleteDialog: !locationStates.deleteDialog
          }
        })
        enqueueSnackbar("Location delelted successfully", { variant: 'success' })
        fetchData()
      })
      .catch((err) => console.log(err))
  }

  const fetchData = (searchValue?: string) => {
    setLocationStates(prev => ({ ...prev, loading: true }))

    Promise.all<any>([
      getAllCategories({ status: "ALL" }),

      getAllLocations({
        status: locationStates.status,
        category: locationStates.categoryId,
        page: searchValue ? 1 : locationStates.page,
        searchValue: searchValue,
      }),
    ])
      .then(async ([categories, locations]) => {
        const data = categories.data;

        const allCategories = data.map((item: any) => ({
          categoryId: item._id,
          label: capitalizeFirstLetter(
            findNameByLanguage(language, item.locationName)
          ),
        }));

        const category = {
          categoryId: null,
          label: "All",
        };

        allCategories.push(category);

        setLocationStates((prev) => {
          return {
            ...prev,
            categories: allCategories,
          };
        });

        const allLocations = locations.data;

        // loop through all locations and calculate total eco savings for each location
        for (let location of allLocations) {
          const { data: ecoSavings } = await getLocationEcoSavings(location._id) as { data: { totalCarbonSavings: number } };

          location.totalCarbonSavings = ecoSavings.totalCarbonSavings
        }

        const list = allLocations.map((obj: any, index: number) => {
          return createRow(obj, index, onEdit, onDelete);
        });

        setLocationStates((prev) => {
          return {
            ...prev,
            locationList: list,
            totalPage: searchValue ? 1 : locations.meta.totalPage,
            loading: false,
          };
        });
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar("Couldn't get data", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      })
      .finally(() => {
        setLocationStates(prev => ({ ...prev, loading: false }))
      });
  };

  /*eslint-disable*/
  useEffect(() => {
    fetchData()
  }, [locationStates.status, locationStates.categoryId, locationStates.page]);

  useEffect(() => {
    if (location) {
      const { homeLocation, latitude, longitude
        , line2, zip, country, state, city } = location
      setLocationStates((prev) => {
        return {
          ...prev,
          value: homeLocation || ""
        }
      })
      const address = {
        line1: [{
          lang: language,
          value: homeLocation || "NA"
        }],
        line2: [{
          lang: language,
          value: line2 || "NA"
        }],
        city: [{
          lang: language,
          value: city || "NA"
        }],
        state: [{
          lang: language,
          value: state || "NA"
        }],
        country: [{
          lang: language,
          value: country || "NA"
        }],
        geo: [latitude, longitude],
        zip: zip || "NA"
      }
      if (address) {
        setPayload({ address })
      }
    }

  }, [location])

  /*eslint-enable*/
  const handleDelete = (id?: string) => {
    setLocationStates((prev: any) => {
      return {
        ...prev,
        deleteDialog: !prev.deleteDialog,
        _action: id
      }
    })
  }

  const updateLocationStatus = (id: string, status: string, event: any) => {
    const newStatus = status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    const payload = {
      status: newStatus
    }
    updateStatus(id, payload).then((res) => {
      fetchData()
      enqueueSnackbar("Updated successfully", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    })
      .catch(() => enqueueSnackbar("Unexpected error occured", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      }))
  }

  const handleStatusChange = (event: any) => {
    setLocationStates((prev) => {
      return {
        ...prev,
        status: event.target.value,
        page: 1
      }
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationStates((prev) => ({
      ...prev,
      value: event.target.value,
    }));
  }

  const handleDialogClose = () => {
    setLocationStates((prev) => {
      return {
        ...prev,
        locationDialog: !locationStates.locationDialog,
        value: ""
      }
    })
  }

  const handleUpdate = () => {
    updateStatus(locationStates._action, payload)
      .then(() => {
        setLocationStates((prev) => {
          return {
            ...prev,
            locationDialog: !locationStates.locationDialog
          }
        })
        fetchData()
      })
      .catch((err) => console.log(err))
  }

  const handleUrlCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    enqueueSnackbar("URL copied", { variant: "success" })
  }

  const createRow = (location: any, index: number, onEdit: any, onDelete: any) => {
    // let url = `${process.env.REACT_APP_WEB_URL}/${getRoute(location.communityType, location.adminId && location.adminId.length > 0 ? true : false)}?id=${location._id}`
    let url = `${process.env.REACT_APP_WHATSAPP_URL}&text=Join ${location.locationName[0].value} carpool community - simply tap the send this message button to start sign up 👉`

    let adminUrl = `${process.env.REACT_APP_WEB_URL}/${getRoute(location.communityType)}?id=${location._id}&admin=true`

    return {
      id: (locationStates.page - 1) * 10 + index + 1,

      category: `${findNameByLanguage(
        language,
        location.categoryId.locationName
      )}`,

      categoryId: location.categoryId._id,

      locationName: `${capitalizeFirstLetter(findNameByLanguage(language, location.locationName))}`,

      address: (
        <Tooltip title={findNameByLanguage(language, location.address.line1)}>
          <p>{formatAddress(location.address.line1, language)}</p>
        </Tooltip>
      ),

      otherFee: location.otherFee,

      onBoardingStatus: (
        <Switch
          checked={location.onBoardingStatus ? true : false}
        />
      ),

      ecologiURL: (
        <div className="d-flex">
          <Tooltip title="Copy URL">
            <ContentCopyIcon className='copy-icon' onClick={() => handleUrlCopy(location.ecologiUrl)} />
          </Tooltip>
          {shortenUrl(location.ecologiUrl)}
        </div>
      ),

      locationURL: (
        <div className='d-flex'>
          <Tooltip title="Copy URL">
            <ContentCopyIcon className='copy-icon' onClick={() => handleUrlCopy(url)} />
          </Tooltip>
          {shortenUrl(url)}
        </div>
      ),

      adminSignUpURL: (
        location.whiteListType === "EMAIL" ? (
          <div className='d-flex'>
            <Tooltip title="Copy URL">
              <ContentCopyIcon className='copy-icon' onClick={() => handleUrlCopy(adminUrl)} />
            </Tooltip>
            {shortenUrl(adminUrl)}
          </div>) : ""
      ),

      status: (
        <Switch
          checked={location.status === "ACTIVE" ? true : false}
          onChange={(e) => updateLocationStatus(location._id, location.status, e)}
        />
      ),

      action: (
        <>
          <Tooltip title="Run simulation">
            <IconButton
              color="primary"
              onClick={() => setLocationStates((prev) => {
                return {
                  ...prev,
                  matchedUsersDialog: !prev.matchedUsersDialog,
                  matchedDialogLocationId: location._id,
                  matchedDialogLocationName: findNameByLanguage(language, location.locationName) as string
                }
              })}
            >
              <PersonSearchIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                handleManage('edit')
                onEdit(location._id)
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="delete">
            <IconButton
              onClick={() => handleDelete(location._id)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Purchase carbon avoidance credits">
            <IconButton
              onClick={() => handlePurchase(location)}
              color="primary"
              disabled={location.totalCarbonSavings - (location.co2Offset ?? 0) < 1}
            >
              <ShoppingCartIcon />
            </IconButton>
          </Tooltip>
        </>
      ),

      co2Savings: location.totalCarbonSavings + ' kg',

      carbonOffset: (location.co2Offset ?? 0) + ' kg',
    };
  }

  const closeMatchedUsersDialog = () => {
    setLocationStates((prev) => {
      return {
        ...prev,
        matchedUsersDialog: !locationStates.matchedUsersDialog,
      };
    });
  };

  const handlePurchase = async (location: any) => {
    setLocationStates(prev => ({ ...prev, loading: true }))

    try {
      const purchaseCarbonCredits = location.totalCarbonSavings - (location.co2Offset ?? 0)

      if (purchaseCarbonCredits < 1) {
        enqueueSnackbar("Not enough carbon savings")

        setLocationStates(prev => ({ ...prev, loading: false }))

        return
      }
      
      const carbonAvoidanceResponse = await purchaseCarbonAvoidanceCredits({
        number: purchaseCarbonCredits,
        units: "KG",
      }) as {data: {response: {number: number}}}

      // update carbon avoidance credits in location
      await updateCarbonOffset(location._id, {
        co2Offset: (carbonAvoidanceResponse.data.response as { number: number }).number
      })

      enqueueSnackbar("Purchase successful", { variant: 'success' })

      // refresh the data
      fetchData()
    } catch (error) {
      console.log('error: ', error)

      enqueueSnackbar("Unable to update location", { variant: 'error' })

      setLocationStates(prev => ({ ...prev, loading: false }))
    }

  }

  return (
    <div>
      {locationStates.loading && <Loader />}

      <Header
        searchPlaceholder="Search by location name"
        onSearch={handleSearch}
        btnText="Add new location"
        onBtnClick={() => handleManage("new")}
        style={{ padding: '24px 0px' }}
        searchInput
      >
        <FormControl className="ml-2" size="small">
          <Select size="small" defaultValue="ALL" onChange={handleStatusChange}>
            <MenuItem value="ALL">All Status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">In-Active</MenuItem>
          </Select>
        </FormControl>

      </Header>

      <Box>
        <CustomTable
          columns={columns}
          rows={locationStates.locationList}
          height="calc(100vh - 248px)"
          errorMessage="Add categories to see the data here"
          pagination={{
            page: locationStates.page,
            totalPages: locationStates.totalPage,
          }}
          onPageChange={onPageChange}
        />
      </Box>
      
      <WarningDialog
        isOpen={locationStates.deleteDialog}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this location?"
      />

      <ManageLocation
        isOpen={locationStates.addLocationDialog}
        locationId={locationStates._action}
        type={locationStates.type}
        fetchData={fetchData}
        onClose={handleClose}
      />

      <MatchedUsers
        isOpen={locationStates.matchedUsersDialog}
        onClose={closeMatchedUsersDialog}
        locationId={locationStates.matchedDialogLocationId}
        locationName={locationStates.matchedDialogLocationName}
        fetchData={fetchData}

      />

      <SetLocation
        latitude={locationStates.latitude}
        longitude={locationStates.longitude}
        value={locationStates.value}
        handleChange={handleChange}
        isOpen={locationStates.locationDialog}
        onClose={handleDialogClose}
        setLocationDetail={setLocation}
        handleSelection={handleUpdate}
      />
    </div>
  );
}

export default Locations
