import { FC, ChangeEvent, useEffect, useState } from "react";
import CustomDialog from "../../../components/mui/dialog";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@mui/material";
import "./index.scss";
import { useLanguage } from "../../../components/language-provider";
import { findNameByLanguage } from "../../../utils";
import { updateUser } from "../../../services/User";
import { getUserDetail } from "../../../services/User";
import { upload } from "../../../services/common";
import { useSnackbar } from "notistack";
// import GoogleMap from "../../../components/google-map";
import * as yup from "yup";
import Loader from "../../../components/mui/loader/Loader";
import { IManageProps, IError } from "../../../interfaces/users";
import { userSchema as schema } from "../../../utils";
import { styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import EditorComponent from "../../../components/free-text-editor";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Download } from "@mui/icons-material";


export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-disabled{
      background:#f4f4f4;
      border-radius: 8px;
    }
    & fieldset {
      border-color: #919eab33;
      border-radius: 8px;
    }
  }
  .MuiOutlinedInput-input {
      height: 2em;
    }
`
export const StyledSelect = styled(Select)({
  '& fieldset': {
     borderColor:'#919eab33',
     borderRadius:'8px'
  },
  '& .MuiOutlinedInput-input': {
   height:'2em'
  },
})

interface IMarketplaceAccessMessage {
  key: string;
  message: string;
  enable: boolean;
}

const Manage: FC<IManageProps> = ({
  isOpen,
  userId,
  type,
  isDisabled,
  onClose,
}) => {
  const [errors, setError] = useState<IError>({});
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();
  isDisabled = type === "view" ? true : false;
  const [location] = useState();
  const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [userInfo, setUserInfo] = useState({
    info: {
      id: userId,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      gender: "",
      photo: "",
      whatsappPhoto: "",
      reg_number: "",
      preference: "",
      homeLocation: "",
      street: "",
      city: "",
      postCode: "",
      state: "",
      geo: [0,0],
      country: "",
      countryCode: "",
      role: null,
      licenceCheckCode: "",
      allowedMarketPlaceUsersCount: 10,
      licenceNumber: "",
      marketPlaceAccessUntil: "",
      marketPlaceAccessMessages: [
        {
          key: "message1",
          message: "",
          enable: false,
        },
        {
          key: "message2",
          message: "",
          enable: false
        }
      ]
    },
    loading: true,
  });
  const [payload, setPayload] = useState({
    _id: userInfo.info.id,
    firstName: [
      {
        lang: language,
        value: "",
      },
    ],
    lastName: [
      {
        lang: language,
        value: "",
      },
    ],
    photo: "",
    whatsappPhoto: "",
    userPreference: "",
    email: "",
    address: {
      line1: [
        {
          lang: language,
          value: "",
        },
      ],
      line2: [
        {
          lang: language,
          value: "",
        },
      ] as any,
      city: [
        {
          lang: language,
          value: "",
        },
      ],
      state: [
        {
          lang: language,
          value: "",
        },
      ],
      country: [
        {
          lang: language,
          value: "",
        },
      ] as any,
      geo: [0,0],
      zip: "",
    },
    countryCode: "",
    phone: "",
    gender: "",
    role: null,
    marketPlaceAccessMessages: [
      {
        key: "message1",
        message: "",
        enable: false,
      },
      {
        key: "message2",
        message: "",
        enable: false
      }
    ],
    marketPlaceAccessUntil: "",
    allowedMarketPlaceUsersCount: 10,
  });
  const [photoUploading, setPhotoUploading] = useState(false);
  const [profilePicToShow, setProfilePicToShow] = useState<undefined | null | string>(undefined);
  const [whatsappProfilePicToShow, setWhatsappProfilePicToShow] = useState<undefined | null | string>(undefined);

  const handleChange = (event:any) => {
    const { name, value } = event.target as { name: string, value:  any};
    if (errors) {
      setError((prev) => ({
        ...prev,
        [name]: false,
      }));
    }

    if(name === "latitude") {
      setUserInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          geo: [value, prevState.info.geo[1]],
        },
      }))

      return
    }

    if(name === "longitude") {
      setUserInfo((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          geo: [prevState.info.geo[0], value],
        },
      }))

      return
    }

    if (name === 'message1' || name === 'message2') {
      setUserInfo(prev => ({
        ...prev,
        info: {
          ...prev.info,
          marketPlaceAccessMessages: prev.info.marketPlaceAccessMessages.map((item: IMarketplaceAccessMessage) => {
            if (item.key === name) {
              item.message = value;
            }
            return item;
          })
        }
      }));

      return
    }

    if (name === 'enable1' || name === 'enable2') {
      const enableToMessageMap = {
        'enable1': 'message1',
        'enable2': 'message2'
      };
      setUserInfo(prev => ({
        ...prev,
        info: {
          ...prev.info,
          marketPlaceAccessMessages: prev.info.marketPlaceAccessMessages.map((item: IMarketplaceAccessMessage) => {
            if (item.key === enableToMessageMap[name]) {
              item.enable = !item.enable;
            }
            return item;
          })
        }
      }));

      return
    }

    setUserInfo((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        [name]: value,
      },
    }));
  };

  /*eslint-disable*/
  useEffect(() => {
    if ((type === "edit" && isOpen) && userId) {
      getUserDetail({ _id: userId }).then((response: any) => {
        setUserInfo((prevState: any) => {
          return {
            ...prevState,
            info: {
              ...prevState.info,
              id: response.user._id,
              firstname: `${findNameByLanguage(
                language,
                response.user.firstName
              )}`,
              lastname: `${findNameByLanguage(
                language,
                response.user.lastName
              )}`,
              email: response.user.email,
              phone: `${response.user.phone}`,
              photo: response.user.photo,
              whatsappPhoto: response.user.whatsappPhoto,
              preference: response.user.userPreference,
              gender: response.user.gender,
              reg_number: response.user.uniqueId,
              role: {
                roleId: response.user.role.roleId._id,
                roleType: response.user.role.roleType,
              },
              homeLocation: findNameByLanguage(
                language,
                response.user.address.line1
              ),
              street: findNameByLanguage(language, response.user.address.line2),
              city: findNameByLanguage(language, response.user.address.city),
              geo: response.user.address.geo,
              postCode: response.user.address.zip,
              state: findNameByLanguage(language, response.user.address.state),
              country: findNameByLanguage(
                language,
                response.user.address.country
              ),
              countryCode: response.user.countryCode,
              licenceCheckCode: response.user.licenceCheckCode,
              allowedMarketPlaceUsersCount: response.user.allowedMarketPlaceUsersCount || 10,
              licenceNumber: response.user.licenceNumber,
              // if marketPlaceAccessMessages is an empty array or undefined, then store the default values, otherwise, set the existing values
              marketPlaceAccessMessages: response.user?.marketPlaceAccessMessages?.length > 0 ? response.user?.marketPlaceAccessMessages : [
                {
                  key: "message1",
                  message: "",
                  enable: false,
                },
                {
                  key: "message2",
                  message: "",
                  enable: false
                }
              ],
              marketPlaceAccessUntil: response.user.marketPlaceAccessUntil,
            },
            loading: false,
          };
        });
        // set editor state to saved html
        if (Boolean(response.user.marketPlaceAccessMessages) !== false) {
          const blocksFromHTML1 = convertFromHTML(response.user.marketPlaceAccessMessages[0]?.message || "");
          const contentState1 = ContentState.createFromBlockArray(blocksFromHTML1.contentBlocks, blocksFromHTML1.entityMap);
          const newEditorState1 = EditorState.createWithContent(contentState1);
          setEditorState1(newEditorState1);

          const blocksFromHTML2 = convertFromHTML(response.user.marketPlaceAccessMessages[1]?.message || "");
          const contentState2 = ContentState.createFromBlockArray(blocksFromHTML2.contentBlocks, blocksFromHTML2.entityMap);
          const newEditorState2 = EditorState.createWithContent(contentState2);
          setEditorState2(newEditorState2);
        }
      });
    }
  }, [userId, isOpen]);

  useEffect(() => {
    if (location && type === "edit") {
      const {
        latitude,
        longitude,
        homeLocation,
        city,
        line2,
        zip,
        state,
        country,
      } = location;

      if (homeLocation) {
        setUserInfo((prevState) => ({
          ...prevState,
          info: {
            ...prevState.info,
            geo: [latitude, longitude],
            homeLocation: homeLocation,
            city: city || "",
            street: line2 || "",
            country: country || "",
            state: state || "",
            postCode: zip || "",
          },
        }));
      }
    }
  }, [location, userId]);
 
  /*eslint-enable*/
  const uploadImage = (e: ChangeEvent<HTMLInputElement> | any) => {
    // if no file is selected, then return nothing
    if(e.target.files.length === 0) return

    const files = e.target.files;

    // restricting the file size to 2.5MB
    const maxSize = 2.5;

    // file size is in bytes and we are converting it into MB
    const fileSize = files[0].size / 1024 / 1024;

    if (files?.length && fileSize < maxSize) {
      setPhotoUploading(true);

      // create new file reader object to read the image content asynchronously to show in the UI
      // https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
      var reader = new FileReader();

      // read the file as base64 encoded url string
      reader.readAsDataURL(files[0])

      // when file reading is complete, then set the base64 encoded url string to the state to show in the UI
      reader.onloadend = function() {
        setProfilePicToShow(reader.result as string);
      }

      upload(files[0])
        .then((response: any) => {
          const updatedUserInfo = {
            ...userInfo,
            info: {
              ...userInfo.info,
              photo: response,
            },
          };
          setUserInfo(updatedUserInfo)
        })
        .catch((err) => console.log(err))
        .finally(() => setPhotoUploading(false))
    } else {
      enqueueSnackbar("File size should be less than 2.5Mb", {
        variant: "error",
      })
    }
  };

  const uploadWhatsappImage = (e: ChangeEvent<HTMLInputElement> | any) => {
     // if no file is selected, then return nothing
     if(e.target.files.length === 0) return

    const files = e.target.files;

    // restricting the file size to 2.5MB
    const maxSize = 2.5;

    // file size is in bytes and we are converting it into MB
    const fileSize = files[0].size / 1024 / 1024;

    if (files?.length && fileSize < maxSize) {
      setPhotoUploading(true);

      // create new file reader object to read the image content asynchronously to show in the UI
      var reader = new FileReader();

      // read the file as base64 encoded url string
      reader.readAsDataURL(files[0])

      // when file reading is complete, then set the base64 encoded url string to the state to show in the UI
      reader.onloadend = function() {
        setWhatsappProfilePicToShow(reader.result as string);
      }

      upload(files[0])
        .then((response: any) => {
          const updatedUserInfo = {
            ...userInfo,
            info: {
              ...userInfo.info,
              whatsappPhoto: response,
            },
          };
          setUserInfo(updatedUserInfo)
        })
        .catch((err) => console.log(err))
        .finally(() => setPhotoUploading(false))
    } else {
      enqueueSnackbar("File size should be less than 2.5Mb", {
        variant: "error",
      })
    }
  };

  const onEdit = () => {};

  const updateData = () => {
    schema
      .validate(userInfo.info, { abortEarly: false })
      .then(() => {
        setError({});
        updateUser(payload)
          .then(() => {
            enqueueSnackbar("Updated successfully", {
              variant: "success",
            });

            setPhotoUploading(false);
            setProfilePicToShow(undefined);
            setWhatsappProfilePicToShow(undefined);

             onClose()
          })
          .catch((err) => {
            enqueueSnackbar("Couldn't update data", {
              variant: "error"
            });
          });
      })
      .catch((error) => {
        console.log(error.inner)
        // Form data is invalid, update the validation errors
        const errors: { [key: string]: string } = {};
        error.inner.forEach((fieldError: yup.ValidationError) => {
          if(fieldError.errors[0].match(/geo\["0"\]/i)) {
            errors["latitude"] = "Invalid input"

            return
          } else if(fieldError.errors[0].match(/geo\["1"\]/i)) {
            errors["longitude"] = "Invalid input"
            
            return
          } else {
            errors[fieldError.path!] = fieldError.message;
          }
        });

        setError(errors);
      });
  };


  /*eslint-disable */
  useEffect(() => {
    setPayload({
      ...payload,
      _id: userInfo.info.id,
      firstName: [
        {
          lang: language,
          value: userInfo.info.firstname,
        },
      ],
      lastName: [
        {
          lang: language,
          value: userInfo.info.lastname,
        },
      ],
      photo: userInfo.info.photo,
      whatsappPhoto: userInfo.info.whatsappPhoto,
      userPreference: userInfo.info.preference,
      email: userInfo.info.email,
      address: {
        line1: [
          {
            lang: language,
            value: userInfo.info.homeLocation,
          },
        ],
        line2: userInfo.info.street !== "" ? [
          {
            lang: language,
            value: userInfo.info.street,
          },
        ] : undefined,
        city: [
          {
            lang: language,
            value: userInfo.info.city,
          },
        ],
        state: [
          {
            lang: language,
            value: userInfo.info.state,
          },
        ],
        country: userInfo.info.country !== "" ? [
          {
            lang: language,
            value: userInfo.info.country,
          },
        ] : undefined,
        geo: userInfo.info.geo,
        zip: userInfo.info.postCode,
      },
      countryCode: userInfo.info.countryCode,
      phone: userInfo.info.phone,
      gender: userInfo.info.gender,
      role: userInfo.info.role,
      marketPlaceAccessMessages: userInfo.info.marketPlaceAccessMessages,
      marketPlaceAccessUntil: userInfo.info.marketPlaceAccessUntil,
      allowedMarketPlaceUsersCount: +userInfo.info.allowedMarketPlaceUsersCount,
    });
  }, [userInfo.info]);

  /*eslint-enable */

  const handleDialogClose = () => {
    setError({});
    onClose();
    setUserInfo((prev)=>{
      return{
        ...prev,
        loading:true
      }
    });
    setPhotoUploading(false);
    setProfilePicToShow(undefined);
    setWhatsappProfilePicToShow(undefined);
  }
  console.log('user info **************** ', userInfo)
  
  return (
    <>
      {(userInfo.loading && isOpen) ? (
        <Loader />
      ) : (
        <CustomDialog
          title={"User Detail"}
          isOpen={isOpen}
          onClose={handleDialogClose}
          onConfirm={updateData}
          disabled={isDisabled}
          onEdit={onEdit}
        >
          {
            photoUploading && <Loader />
          }
          <Box>
            {type === "edit" && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} className="grid-box d-flex items-center justify-center">
                    <Box className="center"  sx={{margin:"16px"}}>
                      <Box onClick={() => {

                        // let url = '';
                        // fetch(userInfo.info.photo)
                        //   .then(response => response.blob())
                        //   .then(blob => {
                        //     url = URL.createObjectURL(blob);

                            const a = document.createElement('a');
                            a.href = userInfo.info.photo;
                            a.download = `${userInfo.info.firstname} ${userInfo.info.lastname}`;
                            a.target="_blank";
                            a.click();
                          // })
                          // .catch(err => {
                          //   enqueueSnackbar(err.message || 'Error occurred while downloading image', {
                          //     variant: 'error'
                          //   })
                          // })
                          // .finally(() => {
                          //   if (url.length > 0) {
                          //     URL.revokeObjectURL(url);
                          //   }
                          // });
                      }}>
                        <Download className="download-image"/>
                      </Box>
                      <Box
                        height="100px"
                        width="100px"
                        className="img-circle"
                        aria-label="upload picture"
                        component="label"
                      >
                        {userInfo.info.photo ? (
                          <img alt="Profile-Pic" src={profilePicToShow ?? userInfo.info.photo} />
                        ) : (
                          <img
                            alt="Default-Pic"
                            src="../../../assets/images/uploadIcon.jpg"
                          />
                        )}
                        <div className="edit-img ">Edit</div>
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={uploadImage}
                        />
                      </Box>
                    </Box>
                    <Box className="center"  sx={{margin:"16px"}}>
                      <Box
                        height="100px"
                        width="100px"
                        className="img-circle"
                        aria-label="upload picture"
                        component="label"
                      >
                        {userInfo.info.whatsappPhoto ? (
                          <img alt="Profile-Pic" src={whatsappProfilePicToShow ?? userInfo.info.whatsappPhoto} />
                        ) : (
                          <img
                            alt="Default-Pic"
                            src="../../../assets/images/uploadIcon.jpg"
                          />
                        )}
                        <div className="edit-img ">Edit WA Profile</div>
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={uploadWhatsappImage}
                        />
                      </Box>
                      <Box onClick={() => {
                        // let url = '';
                        // fetch(userInfo.info.whatsappPhoto)
                        //   .then(response => response.blob())
                        //   .then(response => {
                        //     url = URL.createObjectURL(response);
                            
                            const a = document.createElement('a');
                            a.href = userInfo.info.whatsappPhoto;
                            a.download = `${userInfo.info.firstname} ${userInfo.info.lastname}`;
                            a.target="_blank";
                            a.click();
                          // })
                          // .catch(err => {
                          //   enqueueSnackbar(err.message || 'Error occurred while downloading image', {
                          //     variant: 'error'
                          //   })
                          // })
                          // .finally(() => {
                          //   URL.revokeObjectURL(url);
                          // })
                      }}>
                        <Download className="download-image"/>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                    <StyledTextField
                      label="First Name"
                      name="firstname"
                      value={userInfo.info.firstname}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.firstname && (
                      <small className="error-message">
                        {errors.firstname}
                      </small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                    <StyledTextField 
                      label="Last Name"
                      name="lastname"
                      value={userInfo.info.lastname}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.lastname && (
                      <small className="error-message">{errors.lastname}</small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      label="Email"
                      name="email"
                      value={userInfo.info.email}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.email && (
                      <small className="error-message">{errors.email}</small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      inputProps={{
                        readOnly: true
                      }}
                      label="Phone"
                      name="phone"
                      value={userInfo.info.phone}
                      disabled={isDisabled}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/; // regex to match only digits
                        if (e.target.value === '' || re.test(e.target.value)) {
                            handleChange(e);
                        }
                    }}
                    />
                    {errors && errors.phone && (
                      <small className="error-message">{errors.phone}</small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" >
                        Gender
                      </InputLabel>
                      <StyledSelect
                        size="small"
                        label="gender"
                        name="gender"
                        disabled={isDisabled}
                        value={userInfo.info.gender}
                        onChange={handleChange}
                      >
                        <MenuItem disabled>Select</MenuItem>
                        <MenuItem value="MALE">Male</MenuItem>
                        <MenuItem value="FEMALE">Female</MenuItem>
                      </StyledSelect>
                    </FormControl>
                    {errors && errors.gender && (
                      <small className="error-message">{errors.gender}</small>
                    )}
                  </Grid>

                  {/* show user preference field only for carpool user */}
                  {
                    userInfo.info.preference !== "MARKET_PLACE" && (
                      <Grid item xs={12} md={6} className="grid-box">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Preference
                          </InputLabel>
                          <StyledSelect
                            size="small"
                            value={userInfo.info.preference}
                            name="preference"
                            label="preference"
                            disabled={isDisabled}
                            onChange={handleChange}
                          >
                            <MenuItem disabled>Select</MenuItem>
                            <MenuItem value="OFFER">Offer</MenuItem>
                            <MenuItem value="FINDER">Finder</MenuItem>
                            <MenuItem value="BOTH">Both</MenuItem>
                          </StyledSelect>
                        </FormControl>
                        {errors && errors.preference && (
                          <small className="error-message">
                            {errors.preference}
                          </small>
                        )}
                      </Grid>
                    )
                  }

                  <Grid item xs={12} md={6} className="grid-box">
                    <StyledTextField
                      label="Home Location"
                      name="homeLocation"
                      value={userInfo.info.homeLocation}
                      // disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.homeLocation && (
                      <small className="error-message" style={{ color: "red" }}>
                        {errors.homeLocation}
                      </small>
                    )}  
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      label="Street"
                      name="street"
                      value={userInfo.info.street}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.street && (
                      <small className="error-message" style={{ color: "red" }}>
                        {errors.street}
                      </small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      label="City"
                      name="city"
                      value={userInfo.info.city}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.city && (
                      <small className="error-message" style={{ color: "red" }}>
                        {errors.city}
                      </small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      label="Post Code"
                      name="postCode"
                      value={userInfo.info.postCode}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.postCode && (
                      <small className="error-message" style={{ color: "red" }}>
                        {errors.postCode}
                      </small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      label="State"
                      name="state"
                      value={userInfo.info.state}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.state && (
                      <small className="error-message" style={{ color: "red" }}>
                        {errors.state}
                      </small>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} className="grid-box">
                  <StyledTextField
                      label="Country"
                      name="country"
                      value={userInfo.info.country}
                      disabled={isDisabled}
                      onChange={handleChange}
                    />
                    {errors && errors.country && (
                      <small className="error-message" style={{ color: "red" }}>
                        {errors.country}
                      </small>
                    )}
                  </Grid>

                  {/* if the user is carpool user, then only show the relevant fields */}
                  {
                    userInfo.info.preference !== "MARKET_PLACE" && (
                      <>
                        <Grid item xs={12} md={6} className="grid-box">
                        <StyledTextField
                            label="Latitude"
                            name="latitude"
                            value={userInfo.info.geo[0]}
                            disabled={isDisabled}
                            onChange={handleChange}
                            type="number"
                          />
                          {errors && errors.latitude && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.latitude}
                            </small>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} className="grid-box">
                        <StyledTextField
                            label="Longitude"
                            name="longitude"
                            value={userInfo.info.geo[1]}
                            disabled={isDisabled}
                            onChange={handleChange}
                            type="number"
                          />
                          {errors && errors.longitude && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.longitude}
                            </small>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} className="grid-box">
                        <StyledTextField
                            label="Licence number"
                            name="licenceNumber"
                            value={userInfo.info.licenceNumber}
                            disabled={isDisabled}
                            onChange={handleChange}
                          />
                          {errors && errors.licenceNumber && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.licenceNumber}
                            </small>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} className="grid-box">
                        <StyledTextField
                            label="Licence check code"
                            name="licenceCheckCode"
                            value={userInfo.info.licenceCheckCode}
                            disabled={isDisabled}
                            onChange={handleChange}
                          />
                          {errors && errors.licenceCheckCode && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.licenceCheckCode}
                            </small>
                          )}
                        </Grid>

                        <Grid item xs={12} md={12} className="grid-box">
                          <label style={{ color: "#777", paddingBottom: "0.5rem", display: "block" }}>Marketplace message 1</label>
                          <EditorComponent editorClassName="notes-editor-wrapper" editorState={editorState1} onEditorStateChange={(newEditorState) => {
                            setEditorState1(newEditorState);
                            handleChange({target: {name: 'message1', value: draftToHtml(convertToRaw(editorState1.getCurrentContent()))}})
                          }} />
                          <Switch
                            checked={userInfo.info.marketPlaceAccessMessages.filter(item => item.key === 'message1')[0]?.enable}
                            onChange={handleChange}
                            // disabled={user.userPreference === "FINDER" ? true : false}
                            name="enable1"
                          />
                          <small>Enable Message 1</small>
                          {errors && errors.marketPlaceAccessMessage1 && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.marketPlaceAccessMessage1}
                            </small>
                          )}
                        </Grid>

                        <Grid item xs={12} md={12} className="grid-box">
                          <label style={{ color: "#777", paddingBottom: "0.5rem", display: "block" }}>Marketplace message 2</label>
                          <EditorComponent editorClassName="notes-editor-wrapper" editorState={editorState2} onEditorStateChange={(newEditorState) => {
                            setEditorState2(newEditorState);
                            handleChange({ target: { name: 'message2', value: draftToHtml(convertToRaw(editorState2.getCurrentContent())) } })
                          }} />
                          {errors && errors.marketPlaceAccessMessage2 && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.marketPlaceAccessMessage2}
                            </small>
                          )}
                          <Switch
                            checked={userInfo.info.marketPlaceAccessMessages.filter(item => item.key === 'message2')[0]?.enable}
                            onChange={handleChange}
                            // disabled={user.userPreference === "FINDER" ? true : false}
                            name="enable2"
                          />

                          <small>Enable Message 2</small>
                        </Grid>
                      
                        <Grid item xs={12} md={6} className="grid-box">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="date-container">

                              <DatePicker
                                label={"Marketplace Expiration Date"}
                                className="start-date"
                                value={dayjs(userInfo.info.marketPlaceAccessUntil)}
                                minDate={
                                  userInfo.info.marketPlaceAccessUntil
                                    ? dayjs(userInfo.info.marketPlaceAccessUntil).subtract(4, "month")
                                    : undefined
                                }
                                // maxDate={dayjs(new Date())}
                                onChange={(newValue) =>
                                  setUserInfo((prev) => {
                                    return {
                                      ...prev,
                                      info: {
                                        ...prev.info,
                                        marketPlaceAccessUntil: new Date(`${newValue}`).toISOString()
                                      },
                                    };
                                  })
                                }
                              />
                            </div>
                          </LocalizationProvider>
                          {errors && errors.marektPlaceAccessUntil && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.marektPlaceAccessUntil}
                            </small>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} className="grid-box">
                        <StyledTextField
                            label="Allowed market place invites"
                            name="allowedMarketPlaceUsersCount"
                            value={userInfo.info.allowedMarketPlaceUsersCount}
                            disabled={isDisabled}
                            onChange={handleChange}
                          />
                          {errors && errors.allowedMarketPlaceUsersCount && (
                            <small className="error-message" style={{ color: "red" }}>
                              {errors.allowedMarketPlaceUsersCount}
                            </small>
                          )}
                        </Grid>
                      </>
                    )
                  }
                </Grid>
              </>
            )}
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

Manage.defaultProps = {
  isDisabled: true,
};

export default Manage;